import React from 'react';
import Amplify, { Auth } from 'aws-amplify';
import _axios from '../plugins/axios';

// Get the aws resources configuration parameters
// import awsconfig from './aws-exports'; // if you are using Amplify CLI

const redirectSignInUrl = window.location.origin; // + '//' +  window.location.hostname + window.location.port?;
const redirectSignOutUrl = window.location.origin; // + '//' +  window.location.hostname;
const awsconfig = {
  aws_project_region: 'eu-central-1',
  aws_cognito_region: 'eu-central-1',
  aws_user_pools_id: 'eu-central-1_CgxDyeRpN',
  aws_user_pools_web_client_id: '6dos05fcvktvelpu6umft1g413',
  allowed_oauth_flows_user_pool_client: false,
  identity_provider: 'CLM',
  oauth: {
    domain: 'https://efiport-userpool.auth.eu-central-1.amazoncognito.com',
    scope: ['email', 'openid'],
    identity_provider: 'CLM',
    redirectSignIn: redirectSignInUrl,
    redirectSignOut: redirectSignOutUrl,
    responseType: 'token', // or 'token', note that REFRESH token will only be generated when the responseType is code
    options: {
      AdvancedSecurityDataCollectionFlag: false,
    },
  },
};

Amplify.configure(awsconfig);
const oauth = {
  domain: 'efiport-userpool.auth.eu-central-1.amazoncognito.com',
  scope: ['email', 'openid', 'phone', 'profile', 'aws.cognito.signin.user.admin'],
  redirectSignIn: redirectSignInUrl,
  redirectSignOut: redirectSignOutUrl,
  identity_provider: 'CLM',
  responseType: 'token', // or 'token', note that REFRESH token will only be generated when the responseType is code
  options: {
    AdvancedSecurityDataCollectionFlag: false,
  },
};
// Auth.configure({ oauth: oauth1 });
Auth.configure({ oauth });

/**
 * Compenent for Cognito
 *
 * @class Cognitolayout
 * @extends {React.Component}
 */
class Cognitolayout extends React.Component {
  state = { currentUser: null };

  // const federatedInfo = Cache.getItem('federatedInfo');
  //   // const { token } = federatedInfo;

  // if(!federatedInfo){
  //   Auth.federatedSignIn();
  // }
  

  componentDidMount() {
    const setHeaderToken = (signInUserSession) => {
        if (signInUserSession.idToken && signInUserSession.idToken.jwtToken) {
            _axios.interceptors.request.use(function (config) {
                config.headers.Authorization = `Bearer ${signInUserSession.idToken.jwtToken}`;
                return config;
              }, function (error) {
                return Promise.reject(error);
              });
        }
    };

    let loginTry = 0;
    const doAuth = () => {
      Auth.currentAuthenticatedUser()
        .then((user) => {
          // this.setState({ user });
          setHeaderToken(user.signInUserSession);
          this.setState({
            currentUser: user,
          });
        })
        // eslint-disable-next-line no-console
        .catch(() => {
          if (loginTry < 1) {
            loginTry += 1;
            doAuth();
          } else {
            Auth.federatedSignIn();
          }
        });
    };
    doAuth();
  }

  render() {
    const { children } = this.props;
    const { currentUser } = this.state;
    const isLogin = currentUser && currentUser.signInUserSession;
    const loading = () => <div className="animated fadeIn pt-3 text-center">Loading...</div>;

    // return (
    //   <>
    //     {children}
    //   </>
    // );
    if (!isLogin) {
      return loading();
    }
    return children;
  }
}

export default Cognitolayout;
