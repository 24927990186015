import axios from '../plugins/axios';

let api = {
    async fetchMenuItems(params = {}){
        try{
          let result = await axios.get(`/api/lecturer/navbar/order`); 
          if(result && (result.data instanceof Object)){
            return result.data;
          }else{
            let err = {
              tip: 'fetch menuItem error',
              response: result,
              data: params
            }
            throw err;
          }
        }catch(err){
          throw err;
        }
    },
    async saveMenuItems(params = {}){
        try{
          let result = await axios.put(`/api/lecturer/navbar/order`,params); 
          if(result && (result.data instanceof Object)){
            return result.data;
          }else{
            let err = {
              tip: 'save menuItem error',
              response: result,
              data: params
            }
            throw err;
          }
        }catch(err){
          throw err;
        }
    }
  }
  
  export default api