import * as type from './action-types'

export const defaultState = {
    menuItems : ["/profile","/jobpostings","/applicationsent","/contracts","/calendar","/invoices"]
}

export default(state = defaultState, action={})=>{
    switch (action.type) {
        case type.FETCH_MENU_ITEMS:
            return {
                ...state,
                menuItems: action.menuItems
            }
        case type.SAVE_MENU_ITEMS:
            return {
                ...state,
                menuItems: action.menuItems
            }
        default:
            return state
    }
}