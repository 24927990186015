import React from 'react'
import Sidebar from './Sidebar'

const MainLayout = ({category, section, children}) => {
    return (
        <div>
            <Sidebar category={category} section={section} />
            {children}
        </div>
    )
}

export default MainLayout