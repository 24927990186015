import * as type from './action-types'
// import api from '../api/index'


export const fetchMenuItems = () => {
    return {
        type: type.FETCH_MENU_ITEMS
    }
    // return async dispatch => {
    //     try{
    //       let result = await api.fetchMenuItems();
    //       let menuKeys = result.data.menuKeys;
    //       if(!menuKeys){

    //       }
    //       dispatch({
    //         type: type.FETCH_MENU_ITEMS,
    //         menuItems: menuKeys?menuKeys.split(','):defaultState.menuItems,
    //       })
    //     }catch(err){
    //       console.error(err);
    //     }
    //   }
}

export const saveMenuItems = (menuItems)=>{
    return{
        type : type.SAVE_MENU_ITEMS,
        menuItems
    }
}