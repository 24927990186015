import axios from 'axios'

let baseURL = 'https://dev-clm2-api.efiport2.de';
const _axios = axios.create({
  baseURL,
  timeout: 15000,
  headers: {
    'Content-Type': 'application/json',
    Accept: 'application/json',
  }
});

export default _axios