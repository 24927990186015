import React, {Component} from 'react'
import {Link} from 'react-router-dom'
import $ from 'jquery'
import {DndProvider} from 'react-dnd'
import {HTML5Backend} from 'react-dnd-html5-backend'
import update from 'immutability-helper'
import {connect} from 'react-redux'
import {saveMenuItems} from '../../store/action'
import api from '../../api/index'
import {defaultState} from '../../store/reducers.js'
import {Card} from '../../components/SideMenuCard'
import { Auth } from 'aws-amplify';

const menuItemData = [{  path : "/profile", label : "Profile", icon : "fe-user" },
                        {  path : "/jobpostings", label : "Job Postings Overview" , icon : "fe-share-2"},
                        {  path : "/applicationsent", label : "Application Sent", icon : "fe-grid" },
                        {  path : "/contracts", label : "Contracts", icon : "fe-file-text" },
                        {  path : "/calendar", label : "Calendar", icon : "fe-calendar" },
                        {  path : "/invoices", label : "Invoices", icon : "fe-book" }
                    ];
                    
class Sidebar extends Component {

    state = {
        menuItems : [],
        menuItemCards : [],
    }

    saveMenuItems = async (menuKeys)=>{
        try {
            await api.saveMenuItems({menuKeys: menuKeys});            
        } catch (error) {
            console.error(error);
        }
    }
    moveCard = (dragIndex, hoverIndex) => {
        const dragCard = this.state.menuItemCards[dragIndex];
        let newCards = this.state.menuItemCards;
        newCards = update(newCards, {
            $splice: [
              [dragIndex, 1],
              [hoverIndex, 0, dragCard],
            ],
          });
        this.setState({
            menuItemCards : newCards
        })
        let menuKeys = [];
        newCards.forEach(card=>{
            menuKeys.push(card.path);
        });
        this.saveMenuItems(menuKeys.join(','));
        
    };

    sortMenuItems = async ()=>{
        try {
            let result = await api.fetchMenuItems({});
            let menuItem = [];
            if(result && result.menuKeys){
                menuItem = result.menuKeys.split(',');
            }
            if(menuItem && menuItem.length === defaultState.menuItems.length){
                this.setState({menuItems : menuItem});
            }else{
                this.setState({menuItems : this.props.menuItems});
            }
            let menuItemCards = [];
            this.state.menuItems.forEach(item => {
                menuItemData.forEach(itemData=>{
                    if(itemData.path===item){
                        menuItemCards.push(itemData);
                    }
                })
            });
            this.setState({menuItemCards: menuItemCards });
        } catch (error) {
            console.error(error);
        }
    }
    renderCard = (itemData, index) => {
        return (
          <Card
            key={itemData.path}
            index={index}
            id={itemData.path}
            moveCard={this.moveCard}
          >
            <li className="nav-item">
                                        <Link to={itemData.path} className={`nav-link ${this.props.category === 'profile' ? 'active' : ''}`}>
                                            <i className={`fe ${itemData.icon}`}></i> {itemData.label}
                                        </Link>
                                    </li>
            </Card>
        )
      }
    logout = () => {
        Auth.signOut();
      }
    UNSAFE_componentWillMount() {
        this.sortMenuItems();
    }
    componentDidMount() {
        // this.props.fetchUser();
        
        const $nav = $('.navbar-nav, .navbar-nav .nav');
        const $navCollapse = $('.collapse .navbar-collapse');
    
        function accordion($this) {
            $this.closest($nav).find($navCollapse).not($this).collapse('hide');
        }
    
        $navCollapse.on('show.bs.collapse', function() {
            accordion( $(this) );
        })
    }

    render() {
        return (
            <nav className="navbar navbar-vertical fixed-left navbar-expand-md navbar-dark" id="sidebar">
                <div className="container-fluid">
                    <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#sidebarCollapse" 
                            aria-controls="sidebarCollapse" aria-expanded="false" aria-label="Toggle navigation">
                        <span className="navbar-toggler-icon"></span>
                    </button>
                    <div className="input-group input-group-merge " id="sidebarSearch">
                        <input type="search" className="form-control form-control-prepended search" placeholder="Search"/>
                        <div className="input-group-prepend">
                            <div className="input-group-text">
                                <span className="fe fe-search"></span>
                            </div>
                        </div>
                    </div>
                    <Link to="/" className="navbar-brand">
                        <img src="efiport_logo_neu.jpg" className="navbar-brand-img mx-auto" alt="..." />
                    </Link>
                    <div className="collapse navbar-collapse" id="sidebarCollapse">
                        <DndProvider backend={HTML5Backend}>
                            <ul className="navbar-nav">
                                {this.state.menuItemCards.map((itemData,i)=> this.renderCard(itemData,i))}
                            </ul>
                        </DndProvider>
                        
                        
                        <div className="mt-auto"></div>
                        <div className="navbar-userd-md-flex" id="sidebarUser">
                            <hr className="navbar-divider my-3" />
                            <ul className="navbar-nav">
                                <li className="nav-item">
                                    <Link to="/profilesettings" className={`nav-link ${this.props.category === 'profilesettings' ? 'active' : ''}`}>
                                        <i className="fe fe-settings"></i> Go to Profile Settings
                                    </Link>
                                </li>
                                <li className="nav-item">
                                    <Link to="/" className={`nav-link ${this.props.category === 'logout' ? 'active' : ''}`} onClick={this.logout}>
                                        <i className="fe fe-log-out"></i> Log Out
                                    </Link>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </nav>
        )
    }
}
// export default Sidebar
const mapStateToProps = (state) => {
    return {
        menuItems: state.menuItems
    }
}

export default connect(mapStateToProps, {saveMenuItems})(Sidebar)
